import React, { useState, useEffect } from 'react';
import { formConfig } from './formConfig';
import { Form, Select, Input, DatePicker, Spin } from 'antd';
import { Option } from 'antd/es/mentions';

export default function EditJob({ data, setData }) {
	const [jobData, setJobData] = useState(data);
	const [loading, setLoading] = useState(false);

	const initialValues = {
		...jobData,
		date_posted: jobData.date_posted ? new Date(jobData.date_posted) : null,
	};

	const handleFormValuesChange = (changedValues, allValues) => {
		setData((prevData) => ({ ...prevData, ...changedValues }));
	};

	return (
		<div
			className={loading && 'w-100 d-flex flex-column align-items-center p-2'}>
			{loading === false ? (
				<Form
					name='jobForm'
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 16 }}
					onValuesChange={handleFormValuesChange}
					initialValues={initialValues}>
					{formConfig.map((field) => (
						<Form.Item
							key={field.name}
							label={field.label}
							name={field.name}
							rules={field.rules}>
							{field.type === 'select' ? (
								<Select>
									{field.options.map((option) => (
										<Option key={option.value} value={option.value}>
											{option.label}
										</Option>
									))}
								</Select>
							) : field.type === 'textarea' ? (
								<Input.TextArea />
							) : field.type === 'date' ? (
								<DatePicker />
							) : (
								<Input />
							)}
						</Form.Item>
					))}
				</Form>
			) : (
				<Spin />
			)}
		</div>
	);
}
