import React, { useState, useEffect } from 'react';
import { formConfig } from './formConfig';
import { Form, Select, Input, DatePicker, Spin, message, Button } from 'antd';
import { Option } from 'antd/es/mentions';
import axiosInstance from '../../axiosConfig/axiosConfig';
import { toast } from 'react-hot-toast';
import SpinComponent from '../../components/spin/Spin';
import { useNavigate } from 'react-router-dom';

export default function AddJob({ editId }) {
	const [user, setUser] = useState({});
	const [jobData, setJobData] = useState({
		date_posted: new Date().toISOString().slice(0, 10),
		proposals: 0,
		interviewing: 0,
		invites_sent: 0,
		posted_by: user.user_type,
		posted_by_id: user.id,
		client_name: user.company_name,
		is_premium_job: user.is_premium,
		is_approved: user.is_premium ? true : false,
		is_open: true,
	});
	const [loading, setLoading] = useState(true);
	const initialValues = {
		...jobData,
		date_posted: jobData.date_posted ? new Date(jobData.date_posted) : null,
	};
	const [messageApi, contextHolder] = message.useMessage();
	const navigate = useNavigate()

	const info = (err) => {
		messageApi.info(err);
	};
	const token = localStorage.getItem('user')
		? JSON.parse(localStorage.getItem('user')).token
		: '';

	useEffect(() => {
		axiosInstance.post('/verifyToken/', { token: token ? token : '' }).then(
			(res) => {
				if (
					res.data.user_type ===
					JSON.parse(localStorage.getItem('user')).user_type
				) {
					setUser(res.data);
					setLoading(false)
				}
				setLoading(false)
				setUser(res.data);
			},
			(err) => {
				setLoading(false)
				info('Error Occured: ' + err);
			},
		);
	}, []);

	const PostJob = async (values) => {
		const data = {
			posted_by:
				user.user_type === 'admin' ? 'admin' : user.user_type?.toString(),
			posted_by_id: user.id?.toString(),
			client_name:
				user.user_type === 'admin' ? 'admin' : user.company_name?.toString(),
			is_premium_job:
				user.user_type === 'admin' ? 'true' : user.is_premium?.toString(),
			is_approved:
				user.user_type === 'admin'
					? 'true'
					: user.is_premium
					? 'true'
					: 'false',
			is_open: true,
			date_posted: new Date().toISOString().slice(0, 10),
			proposals: '0',
			interviewing: '0',
			invites_sent: '0',
			...values,
		};
		if (user.is_premium) {
			axiosInstance.post('/jobs/', data).then(
				(res) => {
					toast.success('Job Added Successfully');
					navigate('/rec/jobs')
				},
				(err) => toast.error('Error creating Job'),
			);
		} else {
			axiosInstance.post('/jobs/', data).then(
				(res) => {
					toast.success('Job Post Requested Successfully');
					navigate('/rec/jobs')
				},
				(err) => toast.error('Error creating Job'),
			);
		}
	};

	return (
		<div
			className={
				loading &&
				'w-100 d-flex flex-column align-items-center p-2 justify-content-center'
			}>
			{contextHolder}
			{loading === false ? (
				<Form
					className='p-2 rounded d-flex flex-column shadow-sm m-4'
					name='jobForm'
					style={{ maxWidth: 500 }}
					onFinish={PostJob}
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 16 }}
					initialValues={initialValues}>
					<p className='h6 text-dark text-align-start w-100 mb-2'>Add Job</p>
					{formConfig.map((field) => (
						<Form.Item
							key={field.name}
							label={field.label}
							name={field.name}
							rules={field.rules}>
							{field.type === 'select' ? (
								<Select>
									{field.options.map((option) => (
										<Option key={option.value} value={option.value}>
											{option.label}
										</Option>
									))}
								</Select>
							) : field.type === 'textarea' ? (
								<Input.TextArea />
							) : field.type === 'date' ? (
								<DatePicker />
							) : (
								<Input />
							)}
						</Form.Item>
					))}
					{user.is_premium || user.user_type === 'admin' ? (
						<Form.Item wrapperCol={{ offset: 15, span: 16 }}>
							<Button type='primary' htmlType='submit'>
								Create Job
							</Button>
						</Form.Item>
					) : (
						<Form.Item wrapperCol={{ offset: 12, span: 16 }}>
							<Button type='primary' htmlType='submit'>
								Request for Approval
							</Button>
						</Form.Item>
					)}
				</Form>
			) : (
				<SpinComponent />
			)}
		</div>
	);
}
