import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tag, Button, Spin } from 'antd';
import './job.css';
import axiosInstance from '../../axiosConfig/axiosConfig';
import { toast } from 'react-hot-toast';
import SpinComponent from '../../components/spin/Spin';

export default function JobDetailComponent() {
	const { id } = useParams();
	const [Data, setJob] = useState(null);
	const [applying, setApplying] = useState(false);
	const user_type = JSON.parse(localStorage.getItem('user')).usertype;
	const [loading, setLoading] = useState(true);

	const Call = () => {
		axiosInstance.get(`/jobs/${id}/`).then(
			(res) => {
				setJob(res.data);
				setLoading(false);
			},
			(err) => {
				toast.error('Error getting Job');
				setLoading(false);
			},
		);
	};

	useEffect(() => {
		Call();
	}, []);

	const ApplyJob = () => {
		const user = JSON.parse(localStorage.getItem('user')).user;
		setApplying(true);
		console.log(user);
		const data = {
			job_id: id,
			employee_id: user.id,
			name: user.name,
		};
		axiosInstance.post('/applied/', data).then(
			(res) => {
				toast.success('Job applied successfully');
				Call();
				setApplying(false);
			},
			(err) => {
				toast.error('Error applying Job');
				setApplying(false);
			},
		);
	};

	return (
		<div>
			{loading ? (
				<SpinComponent />
			) : Data !== null ? (
				<div className='d-flex flex-row gap-3 jobdetail__container align-items-start rounded  m-4 border overflow-hidden'>
					<div className='d-flex flex-column w-100 h-100 gap-3 align-items-start overflow-hidden'>
						<p className='h4 mt-4 mx-4 text-dark'>{Data.position}</p>
						<div className='d-flex px-4 flex-column align-items-start'>
							<p className='h6 text-success'>{Data.name}</p>
							<p className='text-secondary'>Posted On: {Data.date_posted}</p>
						</div>
						<div
							style={{ whiteSpace: 'pre-line' }}
							className='w-100 border-top d-flex align-items-start flex-column p-4 text-start'>
							{Data.description}
						</div>
						<div className='w-100 border-top d-flex align-items-start flex-column p-4 text-start'>
							<div className='d-flex flex-row flex-wrap gap-4 align-items-center justify-content-start jobdetails__skillandlocation'>
								<div className='d-flex flex-column align-items-start  text-success h5 text-center border-end p-3 jobdetail__locationdetails'>
									<p>CTC: {Data.ctc}</p>

									<p className='text-danger h6'>
										📌 {Data.location}{' '}
										<span className='text-secondary h6 '>
											{' '}
											| {Data.job_type}
										</span>
									</p>
									<p className='text-secondary'>
										Qualification: {Data.qualificaton}
									</p>
								</div>
								<div className='d-flex flex-row flex-wrap align-items-start p-3'>
									{Data.skills.split(',').map((data, index) => (
										<div key={index}>
											<Tag className='m-2' color='red'>
												{data.toUpperCase()}
											</Tag>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					<div
						className='border-start jobdetail__applynowcontainer'
						style={{ height: '80vh' }}>
						{user_type !== 'admin' && (
							<div className='p-4 d-flex flex-column border-top w-100'>
								{Data.applied ? (
									<Button disabled>Already Applied...</Button>
								) : (
									<Button onClick={ApplyJob}>
										{applying ? <Spin /> : 'Apply Now !'}
									</Button>
								)}
							</div>
						)}
						<div className='border-top w-100 mt-4 p-4 d-flex flex-column gap-4 align-items-start'>
							<div className='text-dark h6 text-start'>
								<p className='text-secondary'>Posted By: </p>
								{Data.posted_user}
							</div>
							<div className='text-dark h6 text-start'>
								<p className='text-secondary'>No of Applied: </p>
								{Data.invites_sent}
							</div>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
}
