import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { RoutingConfig } from './routingConfig';

export default function RoutingComponent() {
	const navigate = useNavigate();
	const location = useLocation();
	useEffect(() => {
		if (!location.pathname.includes('/admin')) {
			if (JSON.parse(localStorage.getItem('user'))) {
				console.log('');
				if (window.location.pathname === '/') {
					navigate('/findjobs');
				}
			} else {
				if (window.location.pathname === '/') {
					navigate('/login');
				} else {
					navigate('/login');
				}
			}
		} else {
			if (location.pathname.includes('/admin')) {
				if (JSON.parse(localStorage.getItem('user'))) {
					console.log('');
				} else {
					navigate('/admin/login');
				}
			}
		}
	}, []);
	return (
		<Routes>
			{RoutingConfig.map((data, key) => (
				<Route key={key} path={data.path} element={data.component}></Route>
			))}
		</Routes>
	);
}
