import { Spin } from 'antd';

const SpinComponent = () => {
	return (
		<div
			style={{
				height: '100vh',
				alignItems: 'center',
				justifyContent: 'center',
				display: 'flex',
			}}>
			<Spin />
		</div>
	);
};

export default SpinComponent;
