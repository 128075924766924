import React, { useState } from 'react';
import {
	Form,
	Input,
	InputNumber,
	DatePicker,
	Switch,
	Button,
	Upload,
	message,
} from 'antd';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../axiosConfig/firebaseConfig';
import { toast } from 'react-hot-toast';

const FormConfig = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
};

const EditForm = ({ formFields, initialValues, onFinish }) => {
	const [imgUrl, setImgUrl] = useState(initialValues.image);
	const [resumeUrl, setResumeUrl] = useState(initialValues.resume);

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (!file) return;

		const storageRef = ref(storage, `files/${file.name}${new Date()}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			'state_changed',
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100,
				);
			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setImgUrl(downloadURL);
					toast.success('Image uploaded successfully');
				});
			},
		);
	};

	const handleFileChange1 = (e) => {
		const file = e.target.files[0];
		if (!file) return;

		const storageRef = ref(storage, `files/${file.name}${new Date()}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			'state_changed',
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100,
				);
			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setResumeUrl(downloadURL);
					toast.success('Image uploaded successfully');
				});
			},
		);
	};

	return (
		<Form
			style={{ height: '60vh', overflow: 'auto' }}
			{...FormConfig}
			initialValues={initialValues}
			onFinish={(values) =>
				onFinish({ ...values, image: imgUrl, resume: resumeUrl })
			}>
			{formFields.map((field) => (
				<Form.Item
					key={field.name}
					label={field.placeholder}
					name={field.name}
					rules={field.rules}>
					{field.type === 'number' ? (
						<InputNumber />
					) : field.type === 'date' ? (
						<DatePicker />
					) : field.type === 'switch' ? (
						<Switch />
					) : (
						<Input />
					)}
				</Form.Item>
			))}
			<Form.Item label={'Image Upload'} name={'image'}>
				<Input value={imgUrl} />
			</Form.Item>
			<input
				className='form-control mb-2'
				type='file'
				onChange={handleFileChange}
			/>
			{JSON.parse(localStorage.getItem('user')).usertype === 'employee' && (
				<>
					<Form.Item label={'Resume Upload'} name={'Resume'}>
						<Input value={resumeUrl} />
					</Form.Item>
					<input
						className='form-control mb-2'
						type='file'
						onChange={handleFileChange1}
					/>
				</>
			)}
			<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
				<Button type='primary' htmlType='submit'>
					Submit
				</Button>
			</Form.Item>
		</Form>
	);
};

export default EditForm;
