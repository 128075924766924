import React, { useState } from 'react';
import axiosInstance from '../../axiosConfig/axiosConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import { Input, Button, Spin } from 'antd';
import { toast } from 'react-hot-toast';

export default function ForgetPassword() {
	const usertype = useLocation().search;
	const navigate = useNavigate();
	const [isOtp, setIsOtp] = useState(false);
	const searchValue = new URLSearchParams(usertype).get('search');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false)
	const [otp, setOtp] = useState('');

	const formSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
		if (!isOtp) {
			axiosInstance
				.post('/forgetpassword/', {
					email,
					user_type: searchValue,
				})
				.then(
					(res) => {
						toast.success('OTP sent successfully');
                        setIsOtp(true);
                        setLoading(false)
					},
					(err) => {
                        toast.error(err.response.data.status);
                        setLoading(false)
					},
				);
		} else {
			axiosInstance
				.post('/validateotp/', {
					email,
					otp,
					password,
					user_type: searchValue,
				})
				.then(
					(res) => {
						toast.success('password Changed Successfully');
						localStorage.clear();
                        navigate('/login');
                        setLoading(false)
					},
					(err) => {
                        toast.error(err.response.data.status);
                        setLoading(false)
					},
				);
		}
	};

	return (
		<div className='d-flex flex-column align-items-center justify-content-center'>
			<div className='m-4 d-flex flex-column gap-2 align-items-start'>
				<Button onClick={(_) => navigate('/login')}>
					<i class='bi bi-arrow-left' />
				</Button>
				<form
					onSubmit={formSubmit}
					className='d-flex flex-column gap-3 p-4 rounded shadow align-items-start signupform__container'>
					<div className='h5 text-align-center align-items-center'>
						{searchValue === 'employee'
							? 'Employee - Password Reset'
							: searchValue === 'recruiter'
							? 'Recruiter - Password Reset'
							: null}
					</div>
					{!isOtp ? (
						<Input
							size={'large'}
							placeholder='Email Address'
							name='email'
							id='email1'
							onChange={(e) => setEmail(e.target.value)}
						/>
					) : (
						<>
							<Input
								size={'large'}
								placeholder='OTP'
								name='otp'
								id='otp'
								onChange={(e) => setOtp(e.target.value)}
							/>
							<Input
								size={'large'}
								placeholder='Password'
								name='password'
								id='password'
								onChange={(e) => setPassword(e.target.value)}
							/>
						</>
					)}
					{!isOtp ? (
						<Button disabled={loading} className="p-2 align-items-center justify-content-center d-flex" ghost type='primary' htmlType='submit'>
							{!loading ? 'Requst OTP' : <Spin />}
						</Button>
					) : (
						<Button disabled={loading} className="p-2 align-items-center justify-content-center d-flex" ghost type='primary' htmlType='submit'>
							{!loading ? 'Change Password' : <Spin />}
						</Button>
					)}
				</form>
			</div>
		</div>
	);
}
