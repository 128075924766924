import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'antd';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import './style.css';
import axiosInstance from '../../../axiosConfig/axiosConfig';
import NodataFound from '../../../components/noData/noDataFound';
import SpinComponent from '../../../components/spin/Spin';

const EmployeesAdmin = () => {
	const [employees, setEmployees] = useState([]);
	const [selectedEmployee, setSelectedEmployee] = useState(0);
	const [isShow, setIsShow] = useState(false);
	const [selectedEmployeeData, setSelectedEmployeeData] = useState([]);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	const Columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			render: (_, record) => (
				<div
					style={{ cursor: 'pointer' }}
					onClick={(_) => {
						setIsShow(true);
						setSelectedEmployee(record.id);
					}}>
					{record.is_premium ? (
						<p className='text-primary'>{record.name}</p>
					) : (
						<p className='text-danger'>{record.name}</p>
					)}
				</div>
			),
		},
		{
			title: 'Contact',
			dataIndex: 'contact',
			key: 'contact',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Qualification',
			dataIndex: 'qualification',
			key: 'qualification',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Experience Years',
			dataIndex: 'experience_years',
			key: 'experience_years',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Experience Months',
			dataIndex: 'experience_months',
			key: 'experience_months',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Current CTC',
			dataIndex: 'current_CTC',
			key: 'current_CTC',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Excepted CTC',
			dataIndex: 'excepeted_CTC',
			key: 'excepeted_CTC',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Profile Summary',
			dataIndex: 'profile_summary',
			key: 'profile_summary',
			render: (text) => <a>{text.slice(0, 20)}...</a>,
		},
	];

	useEffect(() => {
		axiosInstance.get('/employee/').then(
			(res) => {
				setEmployees(res.data);
				setLoading(false);
			},
			(err) => {
				toast.error('Error while getting job');
				setLoading(false);
			},
		);
	}, []);

	useEffect(() => {
		axiosInstance
			.post('/adminwants/', {
				wants: 'employeeapplied',
				requestid: selectedEmployee,
			})
			.then(
				(res) => {
					setSelectedEmployeeData(res.data);
				},
				(err) => toast.error('Error in getting data'),
			);
	}, [isShow]);

	return (
		<div className=''>
			<Modal
				onCancel={() => setIsShow(false)}
				title='Applied Jobs'
				footer={[]}
				open={isShow}>
				<div
					className='d-flex flex-column gap-2 align-items-start'
					style={{ height: 500, overflowY: 'auto' }}>
					<button
						onClick={(_) => navigate(`/employeedetails/${selectedEmployee}`)}>
						View Profile
					</button>
					{selectedEmployeeData?.map((data) => (
						<div className='p-3 border d-flex flex-column border-dark rounded shadow m-2 w-75'>
							<p className='h5'>{data.job_details.name}</p>
							<div className='d-flex flex-row gap-2 mt-1'>
								<p className='p-1 bg-primary text-white'>
									{data.date.slice(0, 10)}
								</p>
								<p
									className={`${
										data.status !== 'accepted' ? 'bg-danger' : 'bg-success'
									} text-white p-1`}>
									{data.status}
								</p>
							</div>
						</div>
					))}
					{selectedEmployee.length === 0 && (
						<p className='h5 text-danger p-2 text-center w-100'>
							No Data Found!
						</p>
					)}
				</div>
			</Modal>

			{!loading ? (
				employees.length > 0 ? (
					<div className='m-4 rounded shadow recJobs__containertable overflow-auto'>
						<Table columns={Columns} dataSource={employees} />
					</div>
				) : (
					<NodataFound />
				)
			) : (
				<SpinComponent />
			)}
		</div>
	);
};

export default EmployeesAdmin;
