export const EmployeeFormConfig = [
  {
    name: "name",
    placeholder: "Name",
  },
  {
    name: "email",
    placeholder: "Email Address",
    type: "email",
  },
  {
    name: "contact",
    placeholder: "Contact",
  },
  {
    name: "qualification",
    placeholder: "Qualification",
  },
  {
    name: "experience_years",
    placeholder: "Experience in Years",
    type: "number",
  },
  {
    name: "experience_months",
    placeholder: "Experience in Months",
    type: "number",
  },
  {
    name: "work_field",
    placeholder: "Work Field",
  },
  {
    name: "profile_summary",
    placeholder: "Profile Summary",
    type: "textarea",
  },
  {
    name: "current_CTC",
    placeholder: "Current CTC",
    type: "number",
  },
  {
    name: "excepeted_CTC",
    placeholder: "Excepted CTC",
    type: "number",
  },
];
