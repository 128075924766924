import Login from "../components/login/login";
import Signup from "../components/signup/signup";
import AddJob from "../pages/addJob/AddJob";
import EmployeesAdmin from "../pages/admin/employees/employeesAdmin";
import JobsAdmin from "../pages/admin/jobs/jobs";
import LoginAdmin from "../pages/admin/login/login";
import EmployeeApproval from "../pages/admin/employeeApproval/EmployeeApproval";
import EmployeeProfileComponent from "../pages/employeeProfile/EmployeeProfile";
import EmployeeDetails from "../pages/employeedetails/EmployeeDetails";
import JobDetailComponent from "../pages/jobDetail/JobDetail";
import Jobs from "../pages/jobs/jobs";
import RecuriterDashboard from "../pages/rec/Dashboard/Dashboard";
import RecuriterJobs from "../pages/rec/Jobs/Jobs";
import { AppliedPeoples } from "../pages/rec/appliedPeoples/AppliedPeoples";
import { RecProfile } from "../pages/rec/profile/RecProfile";
import PremiumProfilesRec from "../pages/rec/premiumProfiles/PremiumProfiles";
import TrainingVideos from "../pages/trainingVideos/TrainingVideos";
import RecAdmin from "../pages/admin/employees/recAdmin";
import { RecDetailsAdmin } from './../pages/rec/profile/RecDetails';
import ForgetPassword from "../components/forgetpassword/forgetPassword";

export const RoutingConfig = [
  { name: "signup", path: "/signup", component: <Signup /> },
  { name: "login", path: "/login", component: <Login /> },
  { name: "findjobs", path: "/findjobs", component: <Jobs /> },
  { name: "trainingVideos", path: "/trainingvideos", component: <TrainingVideos /> },
  {
    name: "viewjobdetail",
    path: "/view/job/employee/:id",
    component: <JobDetailComponent />,
  },
  {
    name: "employeeprofile",
    path: "/employeeprofile",
    component: <EmployeeProfileComponent />,
  },
  {
    name: "employeedetails",
    path: "/employeedetails/:id",
    component: <EmployeeDetails />,
  },
  {
    name: "recdetails",
    path: "/admin/rec/:id1",
    component: <RecDetailsAdmin />,
  },
  {
    name: "viewjobdetail",
    path: "/view/job/admin/:id",
    component: <JobDetailComponent />,
  },
  { name: "recDashboard", path: "/rec", component: <RecuriterDashboard /> },
  { name: "recjobs", path: "/rec/jobs", component: <RecuriterJobs /> },
  { name: "recjobs", path: "/rec/permiumProfiles", component: <PremiumProfilesRec /> },
  { name: "addjob", path: "/addjob", component: <AddJob /> },
  { name: "recprofile", path: "/rec/profile", component: <RecProfile /> },
  { name: "recprofile", path: "/rec/appliedPeople", component: <AppliedPeoples /> },
  { name: "admin", path: "/admin/jobs", component: <JobsAdmin /> },
  { name: "adminlogin", path: "/admin/login", component: <LoginAdmin /> },
  { name: "adminemps", path: "/admin/emp", component: <EmployeesAdmin /> },
  { name: "adminemps", path: "/admin/rec", component: <RecAdmin /> },
  { name: "adminapproval", path: "/admin/appliedemployed", component: <EmployeeApproval /> },
  { name: "adminapproval", path: "/admin/trainingvideos", component: <EmployeeApproval /> },
  { name: "forgetpassword", path: "/forgetpassword", component: <ForgetPassword /> },

];
