import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Button } from 'antd';
import { useEffect, useState } from 'react';
import axiosInstance from './../../axiosConfig/axiosConfig';
import { toast } from 'react-hot-toast';

export default function PeopleCardComponent({ userType, data, getData }) {
	const {
		job_name,
		id,
		name,
		date_of_apply,
		date,
		email,
		contact,
		excepeted_CTC,
		current_CTC,
	} = data.emp_details;

	const [isSameUser, setIsSameUser] = useState(false);
	const navigate = useNavigate();

	const saveStatus = async (action) => {
		const res = await axiosInstance.put('/applied/', {
			id: data.id,
			status: action,
		});
		toast.success('Done!.');
		getData();
	};

	useEffect(() => {
		setIsSameUser(userType.user.id == data.posted_by);
	}, [data]);

	return (
		data.status !== 'Admin_Approval' && (
			<div
				className='d-flex flex-column p-2 border rounded shadow-sm border-1 border-dark'
				style={{ width: '100%', cursor: 'pointer' }}>
				<div
					onClick={(_) => navigate(`/employeedetails/${id}`)}
					className='d-flex flex-column'>
					<div className='h6'>{name}</div>
					<div className='d-flex flex-row flex-wrap gap-2 items-center'>
						<p className='h6'>{email} </p>
						<p className='h6'> | {contact}</p>
					</div>
					<div className='d-flex flex-wrap flex-row gap-2 align-items-center'>
						<p className='p-1 rounded text-white bg-primary'>
							Expected CTC: {excepeted_CTC} LPA
						</p>
						<p className='p-1 rounded text-white bg-danger'>
							Current CTC: {current_CTC} LPA
						</p>
					</div>
				</div>
				{data.status.toLowerCase() === 'pending' ? (
				<div className='flex flex-row gap-2'>
					<Space size='middle'>
						<Button onClick={(_) => saveStatus('selected')} type='dashed'>
							<i className='bi bi-check'></i>
						</Button>
						<Button
							onClick={(_) => saveStatus('rejected')}
							danger
							type='primary'>
							<i className='bi bi-x'></i>
						</Button>
					</Space>
				</div>
				) : (<p className='badge bg-dark p-2 w-25 text-white'>{data.status}</p>)}
			</div>
		)
	);
}
