import React, { useState, useEffect } from 'react';
import { Tooltip, Tabs, Card, Empty } from 'antd';
import '../employeeProfile/employeeprofile.css';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { VerifyToken } from '../../utils';
import axiosInstance from './../../axiosConfig/axiosConfig';
import SpinComponent from './../../components/spin/Spin';

export default function EmployeeDetails() {
	const [data, setData] = useState({});
	const [applied, setApplied] = useState([]);
	const { id } = useParams();
	const [loading, setLoading] = useState(true);

	const items = [
		{
			key: '1',
			label: 'Applied Jobs',
			children: (
				<div>
					{applied.length > 0 ? (
						<div className='d-flex flex-row gap-3 align-items-center'>
							{applied.map((jobData) => (
								<div key={jobData.job_details.id}>
									<Card title={jobData.job_details.name}>
										<div className='d-flex flex-column gap-2 align-items-start'>
											<span>Status: {jobData.status.toUpperCase()}</span>
											<span>
												Date:
												{new Date(jobData.date).toISOString().slice(0, 10)}
											</span>
										</div>
									</Card>
								</div>
							))}
						</div>
					) : (
						<Empty description='No data found' />
					)}
				</div>
			),
		},
		{
			key: '2',
			label: 'Selected',
			children: (
				<div>
					{applied.length > 0 ? (
						<div className='d-flex flex-row gap-3 align-items-center'>
							{applied
								.filter((d) => d.status?.toLowerCase() === 'selected')
								.map((jobData) => (
									<div key={jobData.job_details.id}>
										<Card title={jobData.job_details.name}>
											<div className='d-flex flex-column gap-2 align-items-start'>
												<span>Status: {jobData.status.toUpperCase()}</span>
												<span>
													Date:
													{new Date(jobData.date).toISOString().slice(0, 10)}
												</span>
											</div>
										</Card>
									</div>
								))}
						</div>
					) : (
						<Empty description='No data found' />
					)}
				</div>
			),
		},
		{
			key: '3',
			label: 'Rejected',
			children: (
				<div>
					{applied.length > 0 ? (
						<div className='d-flex flex-row gap-3 align-items-center'>
							{applied
								.filter((d) => d.status?.toLowerCase() === 'rejected')
								.map((jobData) => (
									<div key={jobData.job_details.id}>
										<Card title={jobData.job_details.name}>
											<div className='d-flex flex-column gap-2 align-items-start'>
												<span>Status: {jobData.status.toUpperCase()}</span>
												<span>
													Date:
													{new Date(jobData.date).toISOString().slice(0, 10)}
												</span>
											</div>
										</Card>
									</div>
								))}
						</div>
					) : (
						<Empty description='No data found' />
					)}
				</div>
			),
		},
	];

	useEffect(() => {
		const getData = async () => {
			const data = await axiosInstance.get(`/employee/${id}/`);
			setData(data.data);
			setLoading(false);
		};
		getData();
	}, []);

	useEffect(() => {
		function Call() {
			axiosInstance
				.post('/adminwants/', { wants: 'employeeapplied', requestid: id })
				.then(
					(res) => {
						setApplied(res.data);
					},
					(err) => toast.error('Error getting Data'),
				);
		}
		Call();
	}, []);

	return (
		<div className='d-flex flex-row m-2 p-2 align-items-start justify-content-center employeeprofile__container gap-2'>
			{loading ? (
				<SpinComponent />
			) : (
				data && (
					<div className='d-flex flex-column gap-2 align-items-center  border rounded position-relative employeeprofile__right'>
						<div className='d-flex p-2 flex-column gap-2 align-items-center '>
							<Tooltip
								title={data.is_premium ? 'Premium user' : 'Non Premium User'}>
								<img
									className={`m-2 ${
										!data.is_premium
											? 'border-danger border border-4 p-1'
											: 'border-warning border border-4 p-1'
									}`}
									style={{
										borderRadius: 100,
										width: 200,
										height: 200,
										cursor: 'pointer',
									}}
									src={data.image}
									alt='Avatar'
								/>
							</Tooltip>
							<p className='h5 mt-2'>{data.name}</p>
							<p className='h6 text-secondary'>{data.email}</p>
							<p className='h6 text-secondary'>{data.contact}</p>
						</div>
						<div className='border-top p-3 h6 text-start'>
							{data?.profile_summary?.length > 100
								? `${data?.profile_summary.slice(0, 97)}...`
								: data.profile_summary}
						</div>
						<div className='border-top p-3 w-100 '>
							<div className='text-start employeeprofile__jobdetails w-100'>
								<p className='h6 gap-4 d-flex flex-row justify-content-between w-100'>
									Current CTC:{' '}
									<span className='text-primary'>{data.current_CTC} LPA</span>
								</p>
								<p className='h6 gap-4 d-flex flex-row justify-content-between'>
									Expected CTC:{' '}
									<span className='text-primary'>{data.excepeted_CTC} LPA</span>
								</p>
								<p className='h6 gap-4 d-flex flex-row justify-content-between'>
									Experience:{' '}
									<span className='text-primary'>
										{data.experience_years} Years{' '}
										{data.experience_months !== 0
											? ` and ${data.experience_months}
									Months`
											: null}
									</span>
								</p>
								<p className='h6 gap-4 d-flex flex-row justify-content-between'>
									Qualification:{' '}
									<span className='text-primary'>
										{data.qualification?.toUpperCase()}
									</span>
								</p>
								<p className='h6 d-flex flex-row justify-content-between'>
									Work Field:{' '}
									<span className='text-primary'>{data.work_field}</span>
								</p>
								<p className='h6 d-flex flex-row justify-content-between'>
									Resume:{' '}
									<a
										target='_blank'
										href={data.resume}
										className='text-primary'>
										Download
									</a>
								</p>
							</div>
						</div>
					</div>
				)
			)}
			{/* <div className='m-2 border d-flex flex-column p-4 md:w-100 rounded overflow-auto'>
				<Tabs defaultActiveKey='1' items={items} />
			</div> */}
		</div>
	);
}
