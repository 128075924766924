export const signupFormEmployee = [
  {
    name: "name",
    placeholder: "Name",
  },
  {
    name: "email",
    placeholder: "Email Address",
    type: "email",
  },
  {
    name: "contact",
    placeholder: "Contact Number",
  },
  {
    name: "qualification",
    placeholder: "Qualification",
  },
  {
    name: "work_field",
    placeholder: "Work Field",
  },
  {
    name: "profile_summary",
    placeholder: "Profile Summary",
  },
  {
    name: "current_CTC",
    placeholder: "Current CTC",
    type: "number",
  },
  {
    name: "excepeted_CTC",
    placeholder: "Excepted CTC",
    type: "number",
  },
  {
    name: "password",
    placeholder: "Password",
    type: "password",
  },
];

export const signupFormRecruiter = [
  { name: "name", placeholder: "Name" },
  { name: "company_name", placeholder: "Company Name" },
  { name: "company_type", placeholder: "Company Type" },
  { name: "email", placeholder: "Email Address", type: "email" },
  { name: "contact", placeholder: "Contact Number" },
  { name: "password", placeholder: "Password", type: "password" },
];
