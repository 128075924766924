import React from 'react';
import { Spin } from 'antd';

export const EmployeeNavbar = ({ data }) => {
	return (
		<div>
			<nav className='navbar navbar-expand-lg bg-body-tertiary'>
				<div className='container-fluid'>
					<a className='navbar-brand' href='/'>
						Arha
					</a>
					<button
						className='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarNav'
						aria-controls='navbarNav'
						aria-expanded='false'
						aria-label='Toggle navigation'>
						<span className='navbar-toggler-icon'></span>
					</button>
					<div className='collapse navbar-collapse' id='navbarNav'>
						<ul className='navbar-nav'>
							{data !== 'loading' ? (
								<>
									<li className='nav-item'>
										<a
											className='nav-link'
											aria-current='page'
											href='/findjobs'>
											Find work
										</a>
									</li>
									<li className='nav-item'>
										<a className='nav-link' href='/trainingvideos'>
											Training Videos
										</a>
									</li>
									<li className='nav-item'>
										<a className='nav-link' href='/employeeProfile'>
											Profile
										</a>
									</li>
									<li className='nav-item'>
										<a
											href="#"
											className='nav-link'
											onClick={(_) => {
												localStorage.clear();
												window.location.href = '/login';
											}}>
											Logout
										</a>
									</li>
								</>
							) : (
								<Spin />
							)}
						</ul>
					</div>
				</div>
			</nav>
		</div>
	);
};

export const RecuriterNavbar = () => {
	return (
		<div>
			<nav className='navbar navbar-expand-lg bg-body-tertiary'>
				<div className='container-fluid'>
					<a className='navbar-brand' href='/rec'>
						Arha
					</a>
					<button
						className='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarNav'
						aria-controls='navbarNav'
						aria-expanded='false'
						aria-label='Toggle navigation'>
						<span className='navbar-toggler-icon'></span>
					</button>
					<div className='collapse navbar-collapse' id='navbarNav'>
						<ul className='navbar-nav'>
							<li className='nav-item'>
								<a className='nav-link' aria-current='page' href='/rec/jobs'>
									Jobs
								</a>
							</li>
							{/* <li className='nav-item'>
								<a className='nav-link' href='/rec/appliedPeople'>
									Applied Peoples
								</a>
							</li> */}
							<li className='nav-item'>
								<a className='nav-link' href='/addjob'>
									Post Job
								</a>
							</li>
							<li className='nav-item'>
								<a className='nav-link' href='/rec/permiumProfiles'>
									Premium Resumes
								</a>
							</li>
							<li className='nav-item'>
								<a className='nav-link' href='/rec/profile'>
									Profile
								</a>
							</li>
							<li className='nav-item'>
								<a
									className='nav-link'
									href="#"
									onClick={(_) => {
										localStorage.clear();
										window.location.href = '/';
									}}>
									Logout
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</div>
	);
};

export const AdminNavbar = () => {
	return (
		<div>
			<nav className='navbar navbar-expand-lg bg-body-tertiary'>
				<div className='container-fluid'>
					<a className='navbar-brand' href='/rec'>
						Arha
					</a>
					<button
						className='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarNav'
						aria-controls='navbarNav'
						aria-expanded='false'
						aria-label='Toggle navigation'>
						<span className='navbar-toggler-icon'></span>
					</button>
					<div className='collapse navbar-collapse' id='navbarNav'>
						<ul className='navbar-nav'>
							<li className='nav-item'>
								<a className='nav-link' aria-current='page' href='/admin/jobs'>
									Jobs
								</a>
							</li>
							<li className='nav-item'>
								<a className='nav-link' href='/admin/emp'>
									Employees
								</a>
							</li>
							<li className='nav-item'>
								<a className='nav-link' href='/admin/rec'>
									Recuriters
								</a>
							</li>
							<li className='nav-item'>
								<a className='nav-link' href='/admin/appliedemployed'>
									Applied Peoples
								</a>
							</li>
							<li className='nav-item'>
								<a
									className='nav-link'
									href='http://localhost:8000/admin/api/trainingvideos/'
									target='_blank'>
									Training Videos
								</a>
							</li>
							<li className='nav-item'>
								<a className='nav-link' href='/addjob'>
									Add Job
								</a>
							</li>
							<li className='nav-item'>
								<a
									className='nav-link'
									href="#"
									onClick={(_) => {
										localStorage.clear();
										window.location.href = '/';
									}}>
									Logout
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</div>
	);
};
