import { Badge } from 'antd';
import './job.css';
import { useNavigate } from 'react-router-dom';

export default function JobCardComponent({ job }) {
	const navigate = useNavigate();
	return (
		<Badge.Ribbon
			color={job.tag ? 'green' : 'red'}
			text={job.tag ? job.tag : job.position}>
			<div
				onClick={() => navigate(`/view/job/employee/${job.id}`)}
				className='d-flex flex-column jobcard__container p-4'>
				<div className='d-flex align-items-center justify-content-between'>
					<p className='jobcard__username'>{job.posted_user_name}</p>
				</div>
				<div className='d-flex flex-column gap-2 align-items-start '>
					<div className='jobcard__title'>{job.name}</div>

					<div className='jobcard__des'>
						{job.description.length > 80
							? `${job.description.slice(0, 77)}...`
							: job.description}
					</div>

					<div className='d-flex flex-row gap-1 align-items-start'>
						{job.skills.split(',').length > 2
							? job?.skills
									?.split(',')
									?.slice(0, 2)
									?.map((data, index) => (
										<p className='badge text-secondary shadow-sm' key={index}>
											{data.slice(0, 20)}
										</p>
									))
							: job?.skills
									?.split(',')
									?.slice(0, 2)
									?.map((data, index) => (
										<p className='badge text-secondary shadow-sm' key={index}>
											{data.slice(0, 20)}
										</p>
									))}
						{job.skills.split(',').length > 2 && (
							<p className='badge text-secondary shadow-sm'>
								+{job.skills.split(',').length - 2}
							</p>
						)}
					</div>
				</div>
				<div className='d-flex flex-row gap-2 align-items-center'>
					<div className='badge text-success jobcard__badge shadow-sm d-flex align-items-center'>
						💵 {job.ctc}
					</div>
					<div className='badge border border-light shadow-sm rounded text-secondary'>
						{job.job_type}
					</div>
					<div className='badge border border-light shadow-sm rounded text-secondary'>
						{job.qualification}
					</div>
					<div className='badge border border-light shadow-sm rounded text-secondary'>
						{job.location.length > 15
							? `${job.location.slice(0, 12)}...`
							: job.location}
					</div>
				</div>
			</div>
		</Badge.Ribbon>
	);
}
