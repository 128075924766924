import React, { useEffect, useState } from 'react';
import { Button, Modal, Space, Table, Tag } from 'antd';
import EditJob from '../../editJob/editJob';
import AppliedPeopleJob from '../../../components/appliedPeople/AppliedPeople';
import axiosInstance from '../../../axiosConfig/axiosConfig';
import { toast } from 'react-hot-toast';
import NodataFound from '../../../components/noData/noDataFound';
import { handleDelete } from '../../../utils';
import SpinComponent from '../../../components/spin/Spin';

const RecuriterJobs = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [jobs, setJobs] = useState({});
	const [isShow, setIsShow] = useState(false);
	const [selectedJob, setSelectedJob] = useState({});
	const [loading, setLoading] = useState(true);

	const show = () => {
		setIsShow(true);
	};
	const handleOkShow = () => {
		setIsShow(false);
	};

	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
		axiosInstance.put(`/jobs/${selectedJob.id}/`, selectedJob).then(
			(res) => toast.success('Job Updated Successfully'),
			(err) => toast.error('Error updating job', err),
		);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const approveRejectJob = (status, record) => {
		if (status === 'approve') {
			axiosInstance
				.put(`/jobs/${record.id}/`, { ...record, is_approved: 'true' })
				.then((res) => {
					toast.success('Job Approved');
					getAPI();
				});
		} else {
			axiosInstance
				.put(`/jobs/${record.id}/`, { ...record, is_approved: 'rejected' })
				.then((res) => {
					toast.success('Job Rejected');
					getAPI();
				});
		}
	};

	const Columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Position',
			dataIndex: 'position',
			key: 'position',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'CTC',
			dataIndex: 'ctc',
			key: 'ctc',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Work Type',
			dataIndex: 'work_type',
			key: 'work_type',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
			render: (text) => <a>{text.slice(0, 20)}...</a>,
		},
		{
			title: 'Posted By',
			dataIndex: 'rec_name',
			key: 'rec_name',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Status',
			dataIndex: 'is_approved',
			key: 'is_approved',
			render: (_, record) => (
				<>
					{record.is_approved === 'false' ? (
						<Space size='middle'>
							<Button
								onClick={() => {
									approveRejectJob('approve', record);
								}}
								type='dashed'>
								<i className='bi bi-check'></i>
							</Button>
							<Button
								danger
								onClick={() => {
									approveRejectJob('reject', record);
								}}
								type='primary'>
								<i className='bi bi-x'></i>
							</Button>
						</Space>
					) : record.is_approved === 'true' ? (
						<span style={{ color: 'green' }}>Approved</span>
					) : (
						record.is_approved === 'rejected' && (
							<span style={{ color: 'red' }}>Rejected</span>
						)
					)}
				</>
			),
		},
		{
			title: 'Skills Required',
			dataIndex: 'skills',
			key: 'skills',
			render: (text) => (
				<a>
					{text.split(',').length > 2
						? text
								.split(',')
								.slice(0, 2)
								.map((data) => (
									<Tag color={'red'} key={data}>
										{data.toUpperCase()}
									</Tag>
								))
						: text.split(',').map((data) => (
								<Tag color={'red'} key={data}>
									{data.toUpperCase()}
								</Tag>
						  ))}
					{text.split(',').length > 2 && (
						<Tag color='red'>+ {text.split(',').length - 2}</Tag>
					)}
				</a>
			),
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<Space size='middle'>
					<Button
						onClick={() => {
							show();
							setSelectedJob(record);
						}}
						type='dashed'>
						<i className='bi bi-eye'></i>
					</Button>
					<Button
						onClick={() => {
							showModal();
							setSelectedJob(record);
						}}
						type='primary'>
						<i className='bi bi-pen'></i>
					</Button>
					<Button
						onClick={(_) => handleDelete('job', record.id, jobs, setJobs)}
						danger>
						<i className='bi bi-trash'></i>
					</Button>
				</Space>
			),
		},
	];

	const getAPI = () => {
		axiosInstance.get('/jobs/').then(
			(res) => {
				setJobs(res.data);
				setLoading(false);
			},
			(err) => {
				toast.error('Error while getting job');
				setLoading(false);
			},
		);
	};

	useEffect(() => {
		getAPI();
	}, [isModalOpen]);

	return (
		<div className=''>
			<Modal
				title='Edit Job'
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}>
				<EditJob data={selectedJob} setData={setSelectedJob} />
			</Modal>
			<Modal
				onCancel={() => setIsShow(false)}
				title='Applied People'
				onOk={handleOkShow}
				open={isShow}>
				<AppliedPeopleJob userData={selectedJob} />
			</Modal>

			{loading ? (
				<SpinComponent />
			) : jobs.length > 0 ? (
				<div className='m-4 rounded shadow recJobs__containertable overflow-auto'>
					<Table columns={Columns} dataSource={jobs} />
				</div>
			) : (
				<NodataFound />
			)}
		</div>
	);
};

export default RecuriterJobs;
