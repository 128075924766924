export const formConfig = [
	{
		label: 'Job Title',
		name: 'name',
		type: 'text',
		rules: [{ required: true, message: 'Please enter the job title!' }],
	},
	{
		label: 'Position',
		name: 'position',
		type: 'text',
		rules: [{ required: true, message: 'Please enter the position!' }],
	},
	{
		label: 'CTC',
		name: 'ctc',
		type: 'text',
		rules: [{ required: true, message: 'Please enter the CTC!' }],
	},
	{
		label: 'Qualification',
		name: 'qualification',
		type: 'text',
		rules: [{ required: true, message: 'Please enter the Qualification!' }],
	},
	{
		label: 'Job Type',
		name: 'job_type',
		type: 'select',
		options: [
			{ value: 'Full-time', label: 'Full-time' },
			{ value: 'Part-time', label: 'Part-time' },
			{ value: 'Freelance', label: 'Freelance' },
			{ value: 'Contract', label: 'Contract' },
		],
		rules: [{ required: true, message: 'Please select the job type!' }],
	},
	{
		label: 'Description',
		name: 'description',
		type: 'textarea',
		rules: [{ required: true, message: 'Please enter the job description!' }],
	},
	{
		label: 'Work Type',
		name: 'work_type',
		type: 'select',
		options: [
			{ value: 'On site', label: 'On site' },
			{ value: 'Remote', label: 'Remote' },
			{ value: 'Hybrid', label: 'Hybrid' },
		],
	},
	{
		label: 'Skills',
		name: 'skills',
		type: 'text',
	},
	{
		label: 'Location',
		name: 'location',
		type: 'text',
		rules: [{ required: true, message: 'Please enter the location!' }],
	},
];
