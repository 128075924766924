import axiosInstance from './axiosConfig/axiosConfig';
import { toast } from 'react-hot-toast';

export async function VerifyToken() {
	const Token = JSON.parse(localStorage?.getItem('user')).token;
	const res = await axiosInstance
		.post('/verifyToken/', {
			token: localStorage.getItem('user') ? Token : '',
		})
		.then(
			(res) => {
				return res.data;
			},
			(err) => {
				localStorage.clear();
				window.location.href = '/login';
			},
		);
	return res;
}

export async function GetLocations() {
	const res = await axiosInstance.get('/fetchlocations/');
	return res.data;
}

export const handleDelete = async (data1, id, data, setData) => {
	if (data1 === 'job') {
		const res = await axiosInstance.delete(`/jobs/${id}/`);
		const updatedData = data.filter((dd) => dd.id !== id);
		toast.success(res.data.status);
		setData(updatedData);
		return res.data;
	}
};
