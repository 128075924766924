import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDL1TKiu4Xg61PTSPZkfg0PK7GqTkejtVY",
  authDomain: "arha-10e43.firebaseapp.com",
  projectId: "arha-10e43",
  storageBucket: "arha-10e43.appspot.com",
  messagingSenderId: "261053417583",
  appId: "1:261053417583:web:da5d640679b8eaa26da59a"
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);