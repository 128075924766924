import React, { useState } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Spin, Input } from 'antd';
import toast from 'react-hot-toast';
import LoginImage from '../../assets/login.jpg';
import ArhaLogo from '../../assets/ahralogo.png';
import { useNavigate } from 'react-router-dom';
import './login.css';
import axiosInstance from '../../axiosConfig/axiosConfig';

const Login = () => {
	const [login, setLogin] = useState({
		username: '',
		password: '',
		user_type: 'employee',
	});
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const formSubmit = (e) => {
		e.preventDefault();
		const form = new FormData(e.currentTarget);
		const email = form.get('email');
		const password = form.get('password');
		setLoading(true);
		axiosInstance
			.post('/login/', {
				user_name: email,
				password: password,
				user_type: login.user_type,
			})
			.then(
				(res) => {
					toast.success('Login Successfull!');
					localStorage.setItem('user', JSON.stringify(res.data));
					if (login.user_type === 'employee') {
						window.location.href = '/findjobs';
					} else if (login.user_type === 'recruiter') {
						window.location.href = '/rec';
					}
				},
				(err) => {
					toast.error('User Credientials Failed');
					setLoading(false);
				},
			);
		console.log({ email, password });
	};

	return (
		<div className='d-flex flex-row flex-wrap align-items-center justify-content-evenly p-4 login__container'>
			<div className='d-flex flex-column gap-2 align-items-center justify-content-center'>
				<img className='login__arhalogo' src={ArhaLogo} alt='Arha Logo' />
				<div className='d-flex flex-row gap-3 justify-content-center w-100'>
					<div
						onClick={() => setLogin({ ...login, user_type: 'employee' })}
						className={`${
							login.user_type === 'employee'
								? 'btn btn-primary p-2'
								: 'btn btn-outline-primary p-2 '
						}`}>
						Employee
					</div>
					<div
						onClick={(_) => setLogin({ ...login, user_type: 'recruiter' })}
						className={`${
							login.user_type === 'recruiter'
								? 'btn btn-primary p-2'
								: 'btn btn-outline-primary p-2 '
						}`}>
						Recruiter
					</div>
				</div>
				<div className='d-flex flex-column gap-2 align-items-start'>
					<form onSubmit={formSubmit}>
						<div className='d-flex flex-column align-items-start gap-3 p-4 border border-light rounded shadow-sm'>
							<Input
								size={'large'}
								placeholder='Email Address'
								name='email'
								id='email1'
							/>
							<Input.Password
								size={'large'}
								placeholder='Password'
								name='password'
								iconRender={(visible) =>
									visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
								}
							/>
							<div className='d-flex align-items-center justify-content-between gap-2'>
								{!loading ? (
									<input
										type='submit'
										className='btn btn-outline-primary'
										value='Login'
									/>
								) : (
									<Spin />
								)}
								<Button
									type='link'
									onClick={(_) =>
										navigate(`/forgetpassword?search=${login.user_type}`)
									}
									className='d-flex align-items-right link justify-content-end'>
									forget password?
								</Button>
							</div>
							<Button
								type={'primary'}
								onClick={(_) => navigate(`/signup?search=${login.user_type}`)}
								className='d-flex align-items-right link justify-content-end'>
								Signup as {login.user_type} ?
							</Button>
						</div>
					</form>
				</div>
			</div>
			<div className='d-flex flex-column align-items-center justify-content-center login__image'>
				<img src={LoginImage} alt='Login Image' />
			</div>
		</div>
	);
};

export default Login;
