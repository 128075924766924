import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosConfig/axiosConfig';
import { VerifyToken } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Table } from 'antd';
import NodataFound from '../../../components/noData/noDataFound';
import SpinComponent from '../../../components/spin/Spin';

const PremiumProfilesRec = () => {
	const [employees, setEmployees] = useState([]);
	const navigate = useNavigate();
	const [searchValue, setSearchValue] = useState('');
	const [loading, setLoading] = useState(true);

	const Columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			render: (_, record) => (
				<div
					style={{ cursor: 'pointer' }}
					onClick={(_) => {
						navigate(`/employeedetails/${record.id}`);
					}}>
					{record.is_premium ? (
						<p className='text-primary'>{record.name}</p>
					) : (
						<p className='text-danger'>{record.name}</p>
					)}
				</div>
			),
		},
		{
			title: 'Contact',
			dataIndex: 'contact',
			key: 'contact',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Qualification',
			dataIndex: 'qualification',
			key: 'qualification',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Experience Years',
			dataIndex: 'experience_years',
			key: 'experience_years',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Experience Months',
			dataIndex: 'experience_months',
			key: 'experience_months',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Current CTC',
			dataIndex: 'current_CTC',
			key: 'current_CTC',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Excepted CTC',
			dataIndex: 'excepeted_CTC',
			key: 'excepeted_CTC',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Profile Summary',
			dataIndex: 'profile_summary',
			key: 'profile_summary',
			render: (text) => <a>{text.slice(0, 20)}...</a>,
		},
	];

	useEffect(() => {
		axiosInstance.get('/employee/').then(
			(res) => {
				setEmployees(res.data);
				setLoading(false);
			},
			(err) => {
				console.error(err.response.data.Status);
				toast.error(err.response.data.Status);
				setLoading(false);
			},
		);
	}, []);

	useEffect(() => {
		VerifyToken();
	}, []);

	return (
		<div className=''>
			<div className='p-2 d-flex flex-column w-25'>
				<input
					type='Search'
					placeholder='Search Data'
					className='form-control'
					onChange={(e) => setSearchValue(e.target.value)}
				/>
			</div>
			{loading ? (
				<SpinComponent />
			) : employees.length > 0 ? (
				<div className='m-4 rounded shadow recJobs__containertable overflow-auto'>
					<Table
						columns={Columns}
						dataSource={employees.filter((data) =>
							data.name.toLowerCase().includes(searchValue),
						)}
					/>
				</div>
			) : (
				<NodataFound />
			)}
		</div>
	);
};

export default PremiumProfilesRec;
