import React, { useState } from 'react';
import axios from 'axios';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Spin } from 'antd';
import toast from 'react-hot-toast';
import LoginImage from '../../../assets/login.jpg';
import ArhaLogo from '../../../assets/ahralogo.png';
import { useNavigate } from 'react-router-dom';
import './login.css';
import axiosInstance from '../../../axiosConfig/axiosConfig';

const LoginAdmin = () => {
	const [login, setLogin] = useState({
		username: '',
		password: '',
		user_type: 'employee',
	});
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const formSubmit = (e) => {
		e.preventDefault();
		const form = new FormData(e.currentTarget);
		const email = form.get('email');
		const password = form.get('password');
		setLoading(true);
		axiosInstance
			.post('/login/', {
				user_name: email,
				password: password,
				user_type: 'admin',
			})
			.then(
				(res) => {
					toast.success('Login Successfull!');
					console.log(res.data);
					localStorage.setItem('user', JSON.stringify(res.data));
					setTimeout(() => {}, 2000);
					window.location.href = '/admin/jobs';
				},
				(err) => {
					toast.error('User Credientials Failed');
					setLoading(false);
				},
			);
		console.log({ email, password });
	};

	return (
		<div className='d-flex flex-row flex-wrap align-items-center justify-content-evenly p-4 login__container'>
			<div className='d-flex flex-column gap-2 align-items-center justify-content-center'>
				<img className='login__arhalogo' src={ArhaLogo} alt='Arha Logo' />
				<div className='d-flex flex-column gap-2 align-items-start'>
					<form onSubmit={formSubmit}>
						<div className='d-flex flex-column align-items-start gap-3 p-4 border border-light rounded shadow-sm'>
							<Input
								size={'large'}
								placeholder='Email Address'
								name='email'
								id='email1'
							/>
							<Input.Password
								size={'large'}
								placeholder='Password'
								name='password'
								iconRender={(visible) =>
									visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
								}
							/>
							<div className='d-flex align-items-center justify-content-between gap-2'>
								{!loading ? (
									<input
										type='submit'
										className='btn btn-primary'
										value='Login as Admin'
									/>
								) : (
									<Spin />
								)}
							</div>
						</div>
					</form>
				</div>
			</div>
			<div className='d-flex flex-column align-items-center justify-content-center login__image'>
				<img src={LoginImage} alt='Login Image' />
			</div>
		</div>
	);
};

export default LoginAdmin;
