const NodataFound = () => {
	return (
		<div>
			<img
				src='https://img.freepik.com/free-vector/hand-drawn-no-data-concept_52683-127829.jpg?size=626&ext=jpg&ga=GA1.1.1087540269.1703418465&semt=ais'
				alt='No data Found'
			/>
		</div>
	);
};

export default NodataFound;
