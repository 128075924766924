import React, { useState, useEffect } from 'react';
import { Input, Button, Spin } from 'antd';
import { signupFormEmployee, signupFormRecruiter } from './signupConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import RegisterIcon from '../../assets/register.jpg';
import { Cloudinary } from '@cloudinary/url-gen';
import './signup.css';
import axiosInstance from '../../axiosConfig/axiosConfig';
import { toast } from 'react-hot-toast';

export default function Signup() {
	const usertype = useLocation().search;
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const searchValue = new URLSearchParams(usertype).get('search');
	const cld = new Cloudinary({ cloud: { cloudName: 'detv4aldl' } });

	const EmployeeFormSubmit = (e) => {
		e.preventDefault();
		const form = new FormData(e.currentTarget);
		setLoading(true);
		let values = [];
		signupFormEmployee.forEach((data) => {
			const tempForm = form.get(data.name);
			const obj = {};
			obj[data.name] = tempForm;
			values.push(obj);
		});
		const newState = values.reduce((result, formData) => {
			return { ...result, ...formData };
		}, {});
		console.log(newState);
		axiosInstance.post('/employee/', newState).then(
			(res) => {
				toast.success('User Created');
				navigate('/login');
				setLoading(false);
			},
			(err) => {
				toast.error('Error creating employee');
				setLoading(false);
			},
		);
	};

	const RecuriterFormSubmit = (e) => {
		e.preventDefault();
		const form = new FormData(e.currentTarget);
		let values = [];
		setLoading(true);
		signupFormRecruiter.forEach((data) => {
			const tempForm = form.get(data.name);
			const obj = {};
			obj[data.name] = tempForm;
			values.push(obj);
		});
		const newState = values.reduce((result, formData) => {
			return { ...result, ...formData };
		}, {});
		console.log(newState);
		axiosInstance.post('/recruiter/', newState).then(
			(res) => {
				toast.success('User Created');
				navigate('/login');
				setLoading(false);
			},
			(err) => {
				setLoading(false);
				toast.error('Error creating Recruiter');
			},
		);
	};

	return (
		<div style={{ height: '100vh' }}>
			{searchValue === 'employee' ? (
				<EmployeeForm formSubmit={EmployeeFormSubmit} loading={loading} />
			) : (
				<RecuriterForm formSubmit={RecuriterFormSubmit} loading={loading} />
			)}
		</div>
	);
}

export const EmployeeForm = ({ formSubmit, loading }) => {
	return (
		<div className='d-flex flex-row gap-4 justify-content-evenly flex-wrap align-items-center  p-4 signup__container'>
			<form
				onSubmit={formSubmit}
				className='d-flex flex-column gap-3 p-4 rounded shadow align-items-start signupform__container'>
				<div className='h5 text-align-center align-items-center'>
					Employee Sign up
				</div>
				{signupFormEmployee.map((inputData, index) => (
					<Input
						size={'large'}
						required
						placeholder={inputData.placeholder}
						type={inputData?.type ? inputData.type : 'text'}
						name={inputData.name}
					/>
				))}
				{!loading ? (
					<Button ghost type='primary' htmlType='submit'>
						Register
					</Button>
				) : (
					<Spin />
				)}
			</form>
			<div>
				<img src={RegisterIcon} alt='registerIcon' />
			</div>
		</div>
	);
};

export const RecuriterForm = ({ formSubmit, loading }) => {
	return (
		<div className='d-flex flex-row gap-4 justify-content-evenly flex-wrap align-items-center  p-4 signup__container'>
			<form
				onSubmit={formSubmit}
				className='d-flex flex-column gap-3 p-4 rounded shadow align-items-start signupform__container'>
				<div className='h5 text-align-center align-items-center'>
					Recuriter Sign up
				</div>
				{signupFormRecruiter.map((inputData, index) => (
					<Input
						size={'large'}
						required
						placeholder={inputData.placeholder}
						type={inputData?.type ? inputData.type : 'text'}
						name={inputData.name}
					/>
				))}
				{!loading ? (
					<Button ghost type='primary' htmlType='submit'>
						Register
					</Button>
				) : (
					<Spin />
				)}
			</form>
			<div>
				<img src={RegisterIcon} alt='registerIcon' />
			</div>
		</div>
	);
};
