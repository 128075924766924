import React, { useState, useEffect } from 'react';
import './jobs.css';
import JobCardComponent from '../../components/jobCard/JobCard';
import axiosInstance from './../../axiosConfig/axiosConfig';
import { toast } from 'react-hot-toast';
import NodataFound from './../../components/noData/noDataFound';
import { Select } from 'antd';
import { SearchIcon } from '../../assets/icons';
import { GetLocations, VerifyToken } from '../../utils';
import SpinComponent from '../../components/spin/Spin';

export default function Jobs() {
	const [search, setSearch] = useState('');
	const [locationSearch, setLocationSearch] = useState('');
	const [jobs, setJobs] = useState([]);
	const [locations, setLocations] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		axiosInstance.get('/jobs/').then(
			(res) => {
				setJobs(res.data);
				setLoading(false);
			},
			(err) => {
				toast.error('Error getting jobs');
				setLoading(false);
			},
		);
	}, []);

	useEffect(() => {
		async function call() {
			const data = await GetLocations();
			setLocations(data);
			console.log(data);
		}
		call();
	}, []);

	return (
		<div className='d-flex flex-column gap-2'>
			<div className='d-flex flex-row flex-wrap gap-3 align-items-center p-3 rounded rounded-lg shadow m-2'>
				<div>
					<div className='d-flex flex-row align-items-center jobs__search'>
						<div className='p-2 text-lg text-gray-500'>
							<SearchIcon />
						</div>
						<input
							onChange={(e) => setSearch(e.target.value)}
							type='search'
							className='jobs__searchInput'
							placeholder='Search gigs'
						/>
					</div>
				</div>
				<div>
					<div className='d-flex flex-row align-items-center jobs__search'>
						<div className='p-2 text-lg text-gray-500'>
							<i class='bi bi-geo-alt-fill'></i>
						</div>
						<select
							value={locationSearch}
							className='search_loaction'
							onChange={(e) => setLocationSearch(e.target.value)}>
							<option style={{ color: 'red' }} disabled value=''>
								Select Location
							</option>

							{locations.map((data) => (
								<option value={data.value}>{data.value}</option>
							))}
						</select>
						<div
							onClick={(_) => setLocationSearch('')}
							className='p-2 text-lg text-gray-500'>
							<i class='bi bi-x'></i>
						</div>
					</div>
				</div>
			</div>
			{loading ? (
				<SpinComponent />
			) : jobs?.length > 0 ? (
				<div className='p-2 d-flex flex-wrap gap-4 w-100 jobs__list'>
					{jobs
						?.filter(
							(data) =>
								data.name?.toLowerCase().includes(search?.toLowerCase()) ||
								data.position?.toLowerCase().includes(search?.toLowerCase()) ||
								data.skills_required
									?.toLowerCase()
									?.includes(search?.toLowerCase()),
						)
						?.filter((data) =>
							data.location
								?.toLowerCase()
								.includes(locationSearch?.toLowerCase()),
						)
						.map((data, index) => (
							<JobCardComponent key={index} job={data} />
						))}
				</div>
			) : (
				<NodataFound />
			)}
		</div>
	);
}
