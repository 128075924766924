import React, { useState, useEffect } from 'react';
import './recprofile.css';
import { Card, Modal, Tooltip } from 'antd';
import EditForm from '../../../components/editForm/editForm';
import { RecFormConfig } from './recProfileConfig';
import axiosInstance from '../../../axiosConfig/axiosConfig';
import { toast } from 'react-hot-toast';
import PurchasePremiumComponent from '../../../components/purchasePremium/PurchasePremium';
import SpinComponent from '../../../components/spin/Spin';

export const RecProfile = () => {
	const [user, setUser] = useState({
		name: 'John Doe',
		company_name: 'TechCorp',
		company_type: 'Technology',
		email: 'john.doe@example.com',
		contact: '1234567890',
		looking_for: 'Software Development',
		date_of_join: '2023-11-19',
		is_premium: false,
		premium_last_subscription_date: '2023-11-01',
		premium_end_date: '2024-01-01',
		image: 'https://picsum.photos/200/300',
		user_name: 'johndoe',
		password: 'securepassword',
		last_login: '2023-11-18T12:30:00',
	});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [premiumModal, setPremiumModal] = useState(false);
	const [loading, setLoading] = useState(true);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		const getData = async () => {
			const Token = JSON.parse(localStorage?.getItem('user')).token;
			const res = await axiosInstance.post('/verifyToken/', {
				token: localStorage.getItem('user') ? Token : '',
			});

			const data = await res.data;
			setUser(data);
			setLoading(false);
		};
		getData();
	}, [isModalOpen]);

	const onEditSubmit = async (values) => {
		axiosInstance.put(`/recruiter/${user.id}/`, values).then(
			(res) => toast.success('Data Updated Successfully'),
			(err) => toast.error('Error updating Data'),
		);
		console.log(values, 'Values');
		handleCancel();
		setUser({});
	};

	const openPurchase = async () => {
		if (!user.is_premium) {
			setPremiumModal(true);
		}
	};

	return (
		<div className='d-flex flex-row gap-2  d-flex flex-row align-items-center justify-content-center'>
			<Modal
				title={`${user.name} - Edit`}
				open={isModalOpen}
				footer={null}
				onOk={handleOk}
				onCancel={handleCancel}>
				<EditForm
					formFields={RecFormConfig}
					initialValues={user}
					onFinish={onEditSubmit}
				/>
			</Modal>{' '}
			<Modal
				open={premiumModal}
				footer={null}
				title={`Purchase Premium`}
				onCancel={() => setPremiumModal(false)}>
				{/*<PurchasePremiumComponent data={data} user={data.user_type} /> */}
				<h4 className='h4 text-danger p-2 shadow-sm '>
					You have Premium Access untill - 2025
				</h4>
			</Modal>
			{loading ? (
				<SpinComponent />
			) : (
				<>
					<div className='d-flex flex-column gap-2 mt-1 align-items-center justify-content-center rounded border border-1 border-secondary '>
						<div className='d-flex p-2 flex-column gap-2 align-items-center'>
							<Tooltip
								title={user.is_premium ? 'Premium user' : 'Non Premium User'}>
								<img
									onClick={openPurchase}
									className={`${
										!user.is_premium
											? 'border-danger border border-4 p-1'
											: 'border-warning border border-4 p-1'
									}`}
									style={{
										borderRadius: 100,
										width: 150,
										height: 150,
										cursor: 'pointer',
									}}
									src={user.image}
									alt='Avatar'
								/>
							</Tooltip>
						</div>
						<div className='border-top border-2 border-secondary'>
							<Card className='user-card position-relative'>
								<div onClick={showModal} className='recprofile__edit'>
									<i className='bi bi-pencil'></i>
								</div>
								<div className='user-card-header'>
									<h3>{user.name}</h3>
									<p>{user.company_name}</p>
								</div>
								<div className='user-card-body'>
									<p>
										<strong>Email:</strong> {user.email}
									</p>
									<p>
										<strong>Contact:</strong> {user.contact}
									</p>
									<p>
										<strong>Looking for:</strong> {user.looking_for}
									</p>
									<p>
										<strong>Date of Join:</strong> {user.date_of_join}
									</p>
									<p>
										<strong>Is Premium:</strong>{' '}
										{user.is_premium ? 'Yes' : 'No'}
									</p>
									<p>
										<strong>Company Type:</strong> {user.company_type}
									</p>
									{user.is_premium ? null : (
										<button className='btn btn-outline-primary w-100'>
											Buy Premium
										</button>
									)}
								</div>
							</Card>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
