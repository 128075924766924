import React, { useState, useEffect } from 'react';
import { Tooltip, Tabs, Card, Empty, Upload } from 'antd';
import './employeeprofile.css';
import toast from 'react-hot-toast';
import { Modal } from 'antd';
import axiosInstance from '../../axiosConfig/axiosConfig';
import EditForm from '../../components/editForm/editForm';
import { EmployeeFormConfig } from './employeeformConfig';
import PurchasePremiumComponent from '../../components/purchasePremium/PurchasePremium';
import SpinComponent from '../../components/spin/Spin';

export default function EmployeeProfileComponent() {
	const [data, setData] = useState({});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [applied, setApplied] = useState([]);
	const [premiumModal, setPremiumModal] = useState(false);
	const [loading, setLoading] = useState(true);

	const items = [
		{
			key: '1',
			label: 'Applied Jobs',
			children: (
				<div>
					{applied.length > 0 ? (
						<div className='d-flex flex-row gap-3 align-items-center'>
							{applied.map((jobData) => (
								<div key={jobData.job_details.id}>
									<Card title={jobData.job_details.name}>
										<div className='d-flex flex-column gap-2 align-items-start'>
											<span>Status: {jobData.status.toUpperCase()}</span>
											<span>
												Date:
												{new Date(jobData.date).toISOString().slice(0, 10)}
											</span>
										</div>
									</Card>
								</div>
							))}
						</div>
					) : (
						<Empty description='No data found' />
					)}
				</div>
			),
		},
		{
			key: '2',
			label: 'Selected',
			children: (
				<div>
					{applied.length > 0 ? (
						<div className='d-flex flex-row gap-3 align-items-center'>
							{applied
								.filter((d) => d.status === 'selected')
								.map((jobData) => (
									<div key={jobData.job_details.id}>
										<Card title={jobData.job_details.name}>
											<div className='d-flex flex-column gap-2 align-items-start'>
												<span>Status: {jobData.status.toUpperCase()}</span>
												<span>
													Date:
													{new Date(jobData.date).toISOString().slice(0, 10)}
												</span>
											</div>
										</Card>
									</div>
								))}
						</div>
					) : (
						<Empty description='No data found' />
					)}
				</div>
			),
		},
		{
			key: '3',
			label: 'Rejected',
			children: (
				<div>
					{applied.length > 0 ? (
						<div className='d-flex flex-row gap-3 align-items-center'>
							{applied
								.filter((d) => d.status === 'rejected')
								.map((jobData) => (
									<div key={jobData.job_details.id}>
										<Card title={jobData.job_details.name}>
											<div className='d-flex flex-column gap-2 align-items-start'>
												<span>Status: {jobData.status.toUpperCase()}</span>
												<span>
													Date:
													{new Date(jobData.date).toISOString().slice(0, 10)}
												</span>
											</div>
										</Card>
									</div>
								))}
						</div>
					) : (
						<Empty description='No data found' />
					)}
				</div>
			),
		},
	];

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		const getData = async () => {
			const Token = JSON.parse(localStorage?.getItem('user')).token;
			const res = await axiosInstance.post('/verifyToken/', {
				token: localStorage.getItem('user') ? Token : '',
			});
			setLoading(false);
			const data = await res.data;
			setData(data);
		};
		getData();
	}, [isModalOpen]);

	useEffect(() => {
		function Call() {
			axiosInstance.get('/applied/').then(
				(res) => {
					setApplied(res.data);
				},
				(err) => toast.error('Error getting Data'),
			);
		}
		Call();
	}, []);

	const onEditSubmit = async (values) => {
		axiosInstance.put(`/employee/${data.id}/`, values).then(
			(res) => toast.success('Data Updated Successfully'),
			(err) => toast.error('Error updating Data'),
		);
		console.log(values, 'Values');
		handleCancel();
		setData({});
	};

	const openPurchase = async () => {
		if (!data.is_premium) {
			setPremiumModal(true);
		}
	};

	return (
		<div
			className={
				!loading &&
				'd-flex flex-row m-2 p-2 align-items-start employeeprofile__container gap-2'
			}>
			<Modal
				title={`${data.name} - Edit`}
				open={isModalOpen}
				footer={null}
				onOk={handleOk}
				onCancel={handleCancel}>
				<EditForm
					formFields={EmployeeFormConfig}
					initialValues={data}
					onFinish={onEditSubmit}
				/>
			</Modal>{' '}
			<Modal
				open={premiumModal}
				footer={null}
				title={`Purchase Premium`}
				onCancel={() => setPremiumModal(false)}>
				{/*<PurchasePremiumComponent data={data} user={data.user_type} /> */}
				<h4 className='h4 text-danger p-2 shadow-sm '>
					You have Premium Access untill - 2025
				</h4>
			</Modal>
			{loading ? (
				<SpinComponent />
			) : (
				data && (
					<div className='d-flex flex-column gap-2 align-items-center  border rounded position-relative employeeprofile__right'>
						<div className='position-absolute m-2 top-0 end-0'>
							<button onClick={showModal} className='btn'>
								<i className='bi bi-pen-fill'></i>
							</button>
						</div>
						<div className='d-flex p-2 flex-column gap-2 align-items-center '>
							<Tooltip
								title={data.is_premium ? 'Premium user' : 'Non Premium User'}>
								<img
									onClick={openPurchase}
									className={`m-2 ${
										!data.is_premium
											? 'border-danger border border-4 p-1'
											: 'border-warning border border-4 p-1'
									}`}
									style={{
										borderRadius: 100,
										width: 200,
										height: 200,
										cursor: 'pointer',
									}}
									src={data.image}
									alt='Avatar'
								/>
							</Tooltip>
							<p className='h5 mt-2'>{data.name}</p>
							<p className='h6 text-secondary'>{data.email}</p>
							<p className='h6 text-secondary'>{data.contact}</p>
						</div>
						<div className='border-top p-3 h6 text-start'>
							{data?.profile_summary?.length > 100
								? `${data?.profile_summary.slice(0, 97)}...`
								: data.profile_summary}
						</div>
						<div className='border-top p-3 w-100 '>
							<div className='text-start employeeprofile__jobdetails w-100'>
								<p className='h6 gap-4 d-flex flex-row justify-content-between w-100'>
									Current CTC:{' '}
									<span className='text-primary'>{data.current_CTC} LPA</span>
								</p>
								<p className='h6 gap-4 d-flex flex-row justify-content-between'>
									Expected CTC:{' '}
									<span className='text-primary'>{data.excepeted_CTC} LPA</span>
								</p>
								<p className='h6 gap-4 d-flex flex-row justify-content-between'>
									Experience:{' '}
									<span className='text-primary'>
										{data.experience_years} Years{' '}
										{data.experience_months !== 0
											? ` and ${data.experience_months}
									Months`
											: null}
									</span>
								</p>
								<p className='h6 gap-4 d-flex flex-row justify-content-between'>
									Qualification:{' '}
									<span className='text-primary'>
										{data.qualification?.toUpperCase()}
									</span>
								</p>
								<p className='h6 d-flex flex-row justify-content-between'>
									Work Field:{' '}
									<span className='text-primary'>{data.work_field}</span>
								</p>
								<p className='h6 d-flex flex-row justify-content-between'>
									Resume:{' '}
									<a
										href={data.resume}
										target='_blank'
										className='text-primary'>
										Resume Download
									</a>
								</p>
							</div>
						</div>
					</div>
				)
			)}
			<div className='m-2 border d-flex flex-column p-4 overflow-auto rounded'>
				<Tabs defaultActiveKey='1' items={items} />
			</div>
		</div>
	);
}
