import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'antd';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import './style.css';
import axiosInstance from '../../../axiosConfig/axiosConfig';
import NodataFound from '../../../components/noData/noDataFound';
import SpinComponent from '../../../components/spin/Spin';

const RecAdmin = () => {
	const [employees, setEmployees] = useState([]);
	const [selectedEmployee, setSelectedEmployee] = useState(0);
	const [isShow, setIsShow] = useState(false);
	const [selectedEmployeeData, setSelectedEmployeeData] = useState([]);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true)

	const Columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			render: (_, record) => (
				<div
					style={{ cursor: 'pointer' }}
					onClick={(_) => {
						setIsShow(true);
						setSelectedEmployee(record.id);
					}}>
					{record.is_premium ? (
						<p className='text-primary'>{record.name}</p>
					) : (
						<p className='text-danger'>{record.name}</p>
					)}
				</div>
			),
		},
		{
			title: 'Contact',
			dataIndex: 'contact',
			key: 'contact',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Company Name',
			dataIndex: 'company_name',
			key: 'company_name',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Join Date',
			dataIndex: 'date_of_join',
			key: 'date_of_join',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Looking For',
			dataIndex: 'looking_for',
			key: 'looking_for',
			render: (text) => <a>{text}</a>,
		},
	];

	useEffect(() => {
		axiosInstance.get('/recruiter/').then(
			(res) => {
				setEmployees(res.data.Status);
				setLoading(false)
			},
			(err) => { toast.error('Error while getting job');  setLoading(false)},
		);
	}, []);

	useEffect(() => {
		axiosInstance
			.post('/adminwants/', {
				wants: 'recposted',
				requestid: selectedEmployee,
			})
			.then(
				(res) => {
					setSelectedEmployeeData(res.data);
				},
				(err) => toast.error('Error in getting data'),
			);
	}, [isShow]);

	return (
		<div className=''>
			<Modal
				onCancel={() => setIsShow(false)}
				title='Applied Jobs'
				footer={[]}
				open={isShow}>
				<div
					className='d-flex flex-column gap-2 align-items-start'
					style={{ height: 500, overflowY: 'auto' }}>
					<button
						onClick={(_) => navigate(`/admin/rec/${selectedEmployee}`)}>
						View Profile
					</button>
					{selectedEmployeeData?.map((data) => (
						<div
							onClick={(_) => navigate(`/view/job/admin/${data.id}`)}
							className='p-3 border d-flex flex-column border-dark rounded shadow m-2 w-75'>
							<p className='h5'>{data.name}</p>
						</div>
					))}
					{selectedEmployee.length === 0 && (
						<p className='h5 text-danger p-2 text-center w-100'>
							No Data Found!
						</p>
					)}
				</div>
			</Modal>

			{!loading ? employees.length > 0 ? (
				<div className='m-4 rounded shadow recJobs__containertable overflow-auto'>
					<Table columns={Columns} dataSource={employees} />
				</div>
			) : (
				<NodataFound />
			) : <SpinComponent />}
		</div>
	);
};

export default RecAdmin;
