import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import axiosInstance from '../../../axiosConfig/axiosConfig';
import { toast } from 'react-hot-toast';
import SpinComponent from '../../../components/spin/Spin';

const RecuriterDashboard = () => {
	const [Data, setData] = useState({
		jobsPosted: 100,
		appliedPeoples: 350,
		selectedPeoples: 120,
		rejected: 200,
		pendingApplications: 30,
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		axiosInstance.get('/redDashboardData/').then(
			(res) => {
				setData(res.data);
				setLoading(false);
			},
			(err) => {
				toast.error('Error getting data');
				setLoading(false);
			},
		);
	}, []);

	return (
		<div className={!loading && 'd-flex flex-row flex-wrap p-2 m-3 gap-2'}>
			{loading ? (
				<SpinComponent />
			) : (
				<>
					<div>
						<Card
							title='Total Jobs Posted'
							style={{
								width: 300,
							}}
							className='shadow-sm'>
							<h1 className='h2'>{Data.jobsPosted}</h1>
						</Card>
					</div>
					<div>
						<Card
							title='Total Applications'
							style={{
								width: 300,
							}}
							className='shadow-sm'>
							<h1 className='h2'>{Data.appliedPeoples}</h1>
						</Card>
					</div>
					<div>
						<Card
							title='Total Selected Applications'
							style={{
								width: 300,
							}}
							className='shadow-sm'>
							<h1 className='h2'>{Data.selectedPeoples}</h1>
						</Card>
					</div>
					<div>
						<Card
							title='Total Rejected Applications'
							style={{
								width: 300,
							}}
							className='shadow-sm'>
							<h1 className='h2'>{Data.rejected}</h1>
						</Card>
					</div>
					<div>
						<Card
							title='Total Pending Applications'
							style={{
								width: 300,
							}}
							className='shadow-sm'>
							<h1 className='h2'>{Data.pendingApplications}</h1>
						</Card>
					</div>
				</>
			)}
		</div>
	);
};

export default RecuriterDashboard;
