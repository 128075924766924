import React, { useEffect, useState } from 'react';
import PeopleCardComponent from '../peopleCard/peopleCard';
import axiosInstance from '../../axiosConfig/axiosConfig';

const AppliedPeopleJob = ({ userData }) => {
	const [data, setData] = useState([]);
	const userType = JSON.parse(localStorage.getItem('user'))

	const getData = () => {
		axiosInstance
			.post(`/adminwants/`, { wants: 'jobsapplied', requestid: userData.id })
			.then(
				(res) => setData(res.data),
				(err) => console.log(err),
			);
	};

	useEffect(() => {
		getData();
	}, [userData]);

	return (
		<div
			className='d-flex flex-column gap-3 align-items-center p-2  overflow-auto'
			style={{ height: '50vh' }}>
			{data && data?.map((data1) => (
				<PeopleCardComponent getData={getData} userType={userType} data={data1} />
			))}
		</div>
	);
};

export default AppliedPeopleJob;
