import axios from 'axios';

// const axiosInstance = axios.create({
// 	baseURL: 'http://127.0.0.1:8000',
// 	headers: {
// 		'Content-Type': 'application/json',
// 		'authorization': localStorage.getItem('user')
// 			? JSON.parse(localStorage.getItem('user')).token
// 			: '123',
// 	},
// });

const axiosInstance = axios.create({
	baseURL: 'https://arha-backend.onrender.com',
	headers: {
		'Content-Type': 'application/json',
		'authorization': localStorage.getItem('user')
			? JSON.parse(localStorage.getItem('user')).token
			: '123',
	},
});

export default axiosInstance;
