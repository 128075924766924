import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosConfig/axiosConfig';
import { VerifyToken } from '../../utils';
import { Modal } from 'antd';
import SpinComponent from '../../components/spin/Spin';

export default function TrainingVideos() {
	const [videos, setVideos] = useState([]);
	const [selectedVideo, setSelectedVideo] = useState();
	const [showModel, setShowModel] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const call = async () => {
			const res = await axiosInstance.get(`/trainingVideos/`);
			setVideos(res.data);
			setLoading(false);
		};
		call();
	}, []);

	useEffect(() => {
		VerifyToken();
	}, []);

	return (
		<div className='d-flex flex-row gap-2 p-4'>
			<Modal
				title='Video preview'
				open={showModel}
				footer={[]}
				onCancel={() => setShowModel(false)}>
				<VideoModel data={selectedVideo} />
			</Modal>
			{loading ? (
				<SpinComponent />
			) : (
				videos.map((data) => (
					<div
						onClick={(_) => {
							setSelectedVideo(data);
							setShowModel(true);
						}}
						className='border border-1 p-3 rounded shadow-sm d-flex flex-column gap-1 align-items-center overflow-hidden'
						style={{ width: 200, cursor: 'pointer' }}>
						<div className='training_videos h6'>{data.title}</div>
					</div>
				))
			)}
		</div>
	);
}

export const VideoModel = ({ data }) => {
	return (
		<div className='d-flex flex-column align-item-center gap-2 justify-content-center mt-2'>
			<div
				className='d-flex align-items-center flex-column'
				style={{ width: 500 }}>
				<iframe
					width='400'
					height='315'
					src={`https://www.youtube.com/embed/${
						data.videoUrl?.split('=')[1]
					}`}></iframe>
			</div>
			<div className='h6 d-flex flex-column align-items-center mt-2'>
				{data.title}
			</div>
			<div className='h6 d-flex flex-column align-items-center'>
				{data.description}
			</div>
		</div>
	);
};
