import React, { useState, useEffect } from 'react';
import { Button, Tag } from 'antd';
import axiosInstance from '../../../axiosConfig/axiosConfig';
import { toast } from 'react-hot-toast';
import NodataFound from '../../../components/noData/noDataFound';

export const AppliedPeoples = () => {
	const [appliedData, setAppliedData] = useState([]);

	useEffect(() => {
		axiosInstance.get('/redDashboardData/').then(
			(res) => {
				setAppliedData(res.data.applications);
			},
			(err) => toast.error('Error getting application data'),
		);
	}, []);

	const changeStatus = (action) => {
		if (action == 'selected') {
		} else if (action == 'rejected') {
		}
	};

	return (
		<div className='d-flex flex-row flex-wrap gap-2 p-2'>
			{appliedData.length > 0 ?
				appliedData.map((applied, index) => (
					<div>
						<AppliedPeopleCard applied={applied} key={index} />
					</div>
				)) : <NodataFound />}
		</div>
	);
};

export const AppliedPeopleCard = ({ applied }) => {
	function truncateText(text, maxLength) {
		if (text?.length > maxLength) {
			return text?.substring(0, maxLength) + '...';
		}
		return text;
	}

	return (
		<div
			style={{ width: 350 }}
			className='d-flex flex-column gap-2 p-4 border border-dark rounded'>
			<div className='h6 cursor-pointer'>{applied.name}</div>
			<div className='d-flex flex-row gap-2'>
				<p className='badge text-white bg-danger'>
					{truncateText(applied?.job_name, 15)}
				</p>
				<p className='badge text-white bg-primary'>
					{truncateText(applied.rec_name, 15)}
				</p>
				<p className='badge text-dark bg-light'>
					{new Date(applied.date_of_apply).toISOString().slice(0, 10)}
				</p>
			</div>
			<div className='d-flex flex-row gap-2 justify-content-end'>
				{applied.status?.toLowerCase() === 'pending' ? (
					<div className='d-flex gap-2 align-items-center'>
						<Button type='primary'>Accept</Button>
						<Button type='primary' danger>
							Reject
						</Button>
					</div>
				) : (
					<Button type='link'>
						<Tag className='disabled' color='gold'>
							{applied.status?.toUpperCase()}
						</Tag>
					</Button>
				)}
			</div>
		</div>
	);
};
