import React, { useEffect, useState } from 'react';
import { Button, Modal, Space, Table } from 'antd';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import '../employees/style.css';
import axiosInstance from '../../../axiosConfig/axiosConfig';
import NodataFound from '../../../components/noData/noDataFound';
import SpinComponent from '../../../components/spin/Spin';

const EmployeeApproval = () => {
	const [employees, setEmployees] = useState([]);
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	const HandleAction = async (action, userId) => {
		const res = await axiosInstance.put('/applied/', {
			id: userId,
			status: action,
		});
		toast.success('Forwarded to Recruiter');
		setSuccess(!success);
	};

	const Columns = [
		{
			title: 'Name',
			dataIndex: 'employeeName',
			key: 'employeeName',
			render: (_, record) => {
				console.log(record);
				return (
					<div
						onClick={(_) => navigate(`/employeedetails/${record.employeeID}/`)}
						style={{ cursor: 'pointer' }}>
						<p className='text-primary'>{record.employeeName}</p>
					</div>
				);
			},
		},
		{
			title: 'Contact',
			dataIndex: 'contact',
			key: 'contact',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Excepted CTC',
			dataIndex: 'excepted_ctc',
			key: 'excepted_ctc',
			render: (text) => <a>{text} LPA</a>,
		},
		{
			title: 'Job Title',
			dataIndex: 'job_name',
			key: 'job_name',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Job Position',
			dataIndex: 'position',
			key: 'position',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'CTC',
			dataIndex: 'ctc',
			key: 'ctc',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Name',
			dataIndex: 'employeeName',
			key: 'employeeName',
			render: (_, record) => (
				<Space size='middle'>
					<Button
						onClick={() => {
							HandleAction('pending', record.application_id);
						}}
						type='dashed'>
						<i className='bi bi-check'></i>
					</Button>
					<Button
						danger
						onClick={() => {
							HandleAction('rejected', record.application_id);
						}}
						type='primary'>
						<i className='bi bi-x'></i>
					</Button>
				</Space>
			),
		},
	];

	useEffect(() => {
		axiosInstance
			.post('/adminwants/', {
				wants: 'adminApprovalEmployee',
			})
			.then(
				(res) => {
					setEmployees(res.data);
					setLoading(false);
				},
				(err) => {
					toast.error('Error in getting data');
					setLoading(false);
				},
			);
	}, [success]);

	return (
		<div className=''>
			{!loading ? (
				employees.length > 0 ? (
					<div className='m-4 rounded shadow recJobs__containertable overflow-auto'>
						<Table columns={Columns} dataSource={employees} />
					</div>
				) : (
					<NodataFound />
				)
			) : (
				<SpinComponent />
			)}
		</div>
	);
};

export default EmployeeApproval;
