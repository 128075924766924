import './App.css';
import Login from './components/login/login';
import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import RoutingComponent from './routing/routing';
import {
	AdminNavbar,
	EmployeeNavbar,
	RecuriterNavbar,
} from './components/navbar/navbar';
import { useEffect, useState } from 'react';
import axiosInstance from './axiosConfig/axiosConfig';

function App() {
	const [data, setData] = useState();

	useEffect(() => {
		if (JSON.parse(localStorage.getItem('user'))) {
			axiosInstance
				.post('/verifyToken/', {
					token: JSON.parse(localStorage.getItem('user')).token,
				})
				.then(
					(res) => setData(res.data),
					(err) => {
						localStorage.setItem('reload', 1);
						if (localStorage.getItem('reload') === 1) {
							window.location.href = '/login';
							localStorage.removeItem('reload');
						} else {
						}
					},
				);
		}
		console.log(data);
	}, []);
	return (
		<div className='App'>
			{data?.user_type === 'employee' ? (
				<EmployeeNavbar />
			) : data?.user_type === 'recruiter' ? (
				<RecuriterNavbar />
			) : data?.user_type === 'admin' ? (
				<AdminNavbar />
			) : window.location.pathname !== '/login' ||  window.location.pathname !== '/admin/login'  ? (
				<EmployeeNavbar data={'loading'} />
			) : null}
			<Toaster />
			<Router>
				<RoutingComponent />
			</Router>
		</div>
	);
}

export default App;
